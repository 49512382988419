import PropTypes from "prop-types"
import React from "react"
import ReactHtmlParser from "react-html-parser"
import AnimationWrapper from "./animationWrapper"

const BodyCard = props => (
  <div id={props.id} className="xl:pb-32 xl:py-1">
    <AnimationWrapper>
      <h2 className="w-60 text-accent h2 xs:text-2xl sm:w-100 sm:text-3xl my-0 md:w-100 xs:w-100">
        {props.title}
      </h2>
      <div className="w-45 ml-auto md:ml-0 sm:ml-0 sm:w-100 sm:py-8 md:w-100 mx-12  xs:py-8 xs:ml-0 xs:w-100">
        <div className="xs:text-base sm:text-base text-accent py-16 md:text-lg body-text p">
          {ReactHtmlParser(props.children)}
        </div>
      </div>
    </AnimationWrapper>
  </div>
)
BodyCard.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
}

BodyCard.defaultProps = {
  title: ``,
  id: null,
}

export default BodyCard
