import PropTypes from "prop-types"
import React from "react"

class PortfolioIcon extends React.Component {
  handleClick(index) {
    this.props.onHovered(index)
  }

  render() {
    return (
      <div
        className={[
          "sm:width-logo w-30 width-logo my-12 company-portfolio-icon flex items-center",
          this.props.selected ? "xs:opacity-100" : "",
        ].join(" ")}
      >
        <img
          src={this.props.url}
          onMouseLeave={() => this.handleClick(null)}
          onMouseEnter={() => this.handleClick(this.props.index)}
          class="lg:w-50"
        />
      </div>
    )
  }
}

PortfolioIcon.propTypes = {
  url: PropTypes.string,
  index: PropTypes.number,
  selected: PropTypes.bool,
}

PortfolioIcon.defaultProps = {
  url: ``,
  selected: false,
  index: null,
}

export default PortfolioIcon
