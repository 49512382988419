import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MainCard from "../components/cards/mainCard"
import BodyCard from "../components/cards/bodyCard"
import PartnersCard from "../components/cards/partnersCard"
import SectionWrapper from "../components/sectionWrapper"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    <SectionWrapper primary={true} padding={false}>
      <MainCard
        title={data.mainSection.content}
        buttonText={data.mainSection.button.text}
      />
    </SectionWrapper>

    {data.contentSection.list.map((section, index) => (
      <SectionWrapper
        key={index}
        id={section.slug}
        primary={(index + 1) % 2 == 0}
      >
        <BodyCard title={section.title}>{section.text}</BodyCard>
      </SectionWrapper>
    ))}

    <SectionWrapper id="solutions">
      {data.detailsSection.list.map((section, index) => (
        <BodyCard key={index} id={section.slug} title={section.title}>
          {section.text}
        </BodyCard>
      ))}
    </SectionWrapper>

    <SectionWrapper primary={true} id={data.partnersSection.slug}>
      <PartnersCard
        title={data.partnersSection.title}
        partners={data.partners.list}
      />
    </SectionWrapper>
    {data.closingSection.list.map((section, index) => (
      <SectionWrapper
        key={index}
        id={section.slug}
        primary={(index + 1) % 2 == 0}
      >
        <BodyCard title={section.title}>{section.text}</BodyCard>
      </SectionWrapper>
    ))}
    <SectionWrapper primary={true} padding={false}>
      <div class="py-32">
        <MainCard
          id={data.contactUsSection.slug}
          title={data.contactUsSection.content}
          buttonText={data.contactUsSection.button.text}
          buttonLink={data.contactUsSection.button.link}
        />
      </div>
    </SectionWrapper>
  </Layout>
)

export default IndexPage

export const queries = graphql`
  query data {
    mainSection: wordpressPost(
      categories: { elemMatch: { slug: { eq: "main-section" } } }
    ) {
      id
      title
      content
      slug
      button: acf {
        text: button_text
      }
    }
    partnersSection: wordpressPost(
      categories: { elemMatch: { slug: { eq: "partners-section" } } }
    ) {
      id
      title
      content
      slug
    }
    partners: allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "partner" } } } }
      sort: { fields: date }
    ) {
      list: nodes {
        title
        text: content
        icon: featured_media {
          url: source_url
        }
        slug
      }
    }
    contentSection: allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "content-section" } } } }
      sort: { fields: date }
    ) {
      list: nodes {
        title
        text: content
        slug
      }
    }
    detailsSection: allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "details-section" } } } }
      sort: { fields: date }
    ) {
      list: nodes {
        title
        text: content
        slug
      }
    }
    closingSection: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: "closing-sections" } } }
      }
      sort: { fields: date }
    ) {
      list: nodes {
        title
        text: content
        slug
      }
    }
    contactUsSection: wordpressPost(
      categories: { elemMatch: { slug: { eq: "contact-us" } } }
    ) {
      id
      title
      content
      slug
      button: acf {
        text: button_text
        link: button_link
      }
    }
  }
`
