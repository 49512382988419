import PropTypes from "prop-types"
import React from "react"
import PortfolioIcon from "../portfolioIcon"
import ReactHtmlParser from "react-html-parser"
import AnimationWrapper from "./animationWrapper"

class PartnersCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: null }
    this.expandText = this.expandText.bind(this)
  }

  expandText(index) {
    // Update state so the next render will show the fallback UI.
    this.setState({ expanded: index })
  }

  render() {
    return (
      <div id={this.props.id}>
        <AnimationWrapper>
          <h2 className="w-60 text-accent h2 xs:text-2xl sm:w-100 sm:text-3xl my-0 md:w-100 xs:w-100">
            {this.props.title}
          </h2>

          <div className=" w-85 mx-auto xs:w-100 sm:w-100 xs:mx-auto md:w-85 md:mx-auto mt-32 xs:mt-12 md:mt-12 py-3 sm:mt-12">
            <div className="flex flex-wrap xs:mx-0 md:w-100 md:mx-0 xs:w-100 w-70 ml-auto sm:w-100 sm:justify-center sm:ml-0">
              {this.props.partners.map((item, i) => (
                <PortfolioIcon
                  key={i}
                  url={item.icon.url}
                  index={i}
                  onHovered={this.expandText}
                  selected={
                    this.state.expanded == null && i == 0 ? true : false
                  }
                />
              ))}
            </div>
            <div className="w-70 ml-auto pt-5 xs:mt-8 xs:mx-0 xs:w-100 sm:w-85 md:mx-0 md:w-100 md:mt-12 min-h-rem-84 sm:w-100 sm:justify-center sm:ml-0">
              {this.props.partners.map((item, index) => (
                <div
                  key={index}
                  className={[
                    this.state.expanded == index ? "block body-text" : "hidden",
                    this.state.expanded == null && index == 0
                      ? "xs:block"
                      : null,
                  ].join(" ")}
                >
                  <h4 className="hidden xs:block my-2 text-accent text-3xl">
                    {item.title}
                  </h4>
                  {ReactHtmlParser(item.text)}
                </div>
              ))}
            </div>
          </div>
        </AnimationWrapper>
      </div>
    )
  }
}

PartnersCard.propTypes = {
  title: PropTypes.string,
  partners: PropTypes.array,
  id: PropTypes.string,
}

PartnersCard.defaultProps = {
  title: ``,
  id: null,
  partners: [
    {
      icon: { url: "img/partners/coffee_circle.png" },
      title: "Coffe-circle",
      text:
        "<p><strong>Who?</strong> A Berlin-based importer and online retailer for fair-trade coffee.</p><p><strong>What?</strong> We wrote test cases and performed manual and automation testing for the online shop. “(Muraho) wrote automation tests, but they also went above and beyond, working directly with our developers to troubleshoot bugs. Their tireless team of testers were professional, communicative, and a pleasure to work with.”, Coffee Circle CEO.</p><p><strong>Why?</strong> To ensure the quality of their online shop, they hired our team to run professional quality assurance alongside their software development team.",
    },
    {
      icon: { url: "img/partners/global_excel.png" },
      title: "Global-excel",
      text:
        "<p><strong>Who?</strong> A cost containment, claims management, and medical assistance company from Canada.</p><p><strong>What?</strong> Our team performs all necessary types of testing, such as end-to-end testing, security testing, performance and API testing, and automated testing, for StandByMD, Global Excel’s mobile app for on-demand outpatient care.</p> <p><strong>Why?</strong> Global Excel needed a dedicated QA team for StandByMD. Our team is responsible for all of the QA testing for this product.</p>",
    },
    {
      icon: { url: "img/partners/jibu.png" },
      title: "Jibu",
      text:
        "<p><strong>Who?</strong> A bottled water company based in Rwanda.</p> <p><strong>What?</strong> We created and implemented a manual testing strategy for the new features of Jibu’s mobile inventory tracking app and also ran stress tests for data loss. Automated testing was performed using different browsers and devices and the test results were delivered on a weekly basis.</p> <p> <strong> Why?</strong> Jibu developed an inventory tracking app that allows their franchisees to log the number of bottles of water they have purchased. They hired our team to test the app so that the franchisees could use it to log their inventories without issues.</p>",
    },
    {
      icon: { url: "img/partners/dalia.png" },
      title: "Dalia",
      text:
        "<p><strong>Who?</strong> A market research company from Berlin.</p><p><strong>What?</strong> Our team worked alongside Dalia’s QA engineers, providing manual testing and automated testing for all their product lines.</p><p><strong>Why?</strong> Dalia is a fast growing company and, as a result, the ratio of developers to QA engineers was imbalanced. Our team supported and thus extended their QA team.</p>",
    },
    {
      icon: { url: "img/partners/coformatique.png" },
      title: "Coformatique",
      text:
        "<p><strong>Who?</strong> A software development company from Egypt.</p><p><strong>What?</strong> Our team works alongside the Coformatique team to carry out a wide range of testing services for different projects such as a French real estate app and a mobile HR app.</p> <p><strong>Why?</strong> Every professional development team needs a QA team that is responsible for testing the software and guaranteeing the quality of the product. Our team runs QA testing before the software is delivered to production and is currently working on several projects together with the Coformatique team. </p>",
    },
    {
      icon: { url: "/img/partners/folkdays.png" },
      title: "Folkdays",
      text:
        "<p><strong>Who?</strong> A sustainable, fair-trade fashion label based in Berlin.</p><p><strong>What?</strong> Our team carries out exploratory testing for their online shop to detect any critical functional bugs and also delivers usability test results.</p><p><strong>Why?</strong> Before launching their new website, FOLKDAYS needed to ensure that the core functionality was working correctly and that all major visual and content bugs were uncovered and fixed.</p>",
    },
  ],
}

export default PartnersCard
