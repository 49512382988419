import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"
import React from "react"
import ReactHtmlParser from "react-html-parser"
import AnimationWrapper from "./animationWrapper"

class MainCard extends React.Component {
  link() {
    if (this.props.buttonLink.includes("#")) {
      return (
        <AnchorLink
          to={"/" + this.props.buttonLink}
          spy={false}
          smooth={true}
          offset={-70}
          duration={1500}
        >
          <button className="btn bg-transparent text-lg font-primary sm:w-50 md:w-40 xs:px-5  border-solid hover:bg-accent hover:text-white transition-250ms border-1 border-accent mx-0 text-accent nav-link-text py-4 px-8">
            {this.props.buttonText}
          </button>
        </AnchorLink>
      )
    }
    return (
      <a href={this.props.buttonLink}>
        <button className="btn bg-transparent text-lg font-primary sm:w-50 md:w-40 xs:px-5  border-solid hover:bg-accent hover:text-white transition-250ms border-1 border-accent mx-0 text-accent nav-link-text py-4 px-8 sm:px-3">
          {this.props.buttonText}
        </button>
      </a>
    )
  }

  render() {
    return (
      <div className="pt-32 xs:pt-4 sm:pt-8 md:pt-0">
        <AnimationWrapper>
          <div id={this.props.id} className="xs:pt-24 md:pt-24">
            <h2 className="text-accent h2 w-65 xs:w-100 md:w-100 xs:text-3xl sm:text-3xl sm:w-85 md:text-8xl">
              {ReactHtmlParser(this.props.title)}
            </h2>
          </div>

          <div className="w-60 text-right xs:text-left md:text-left xs:w-95 xs:mx-auto md:w-90 md:mx-auto sm:mt-0 sm:w-80 sm:mx-auto sm:text-center mt-16 xs:mt-8 py-3 xs:py-0">
            {this.link()}
          </div>
        </AnimationWrapper>
      </div>
    )
  }
}

MainCard.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  id: PropTypes.string,
}

MainCard.defaultProps = {
  title: ``,
  buttonText: "Get in touch",
  buttonLink: "#contact",
  id: null,
}

export default MainCard
